import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { Text } from '@magalu/stereo-ui/atoms';
var RecommendedProductTitle = function RecommendedProductTitle(props) {
  return React.createElement(Text, _extends({
    "data-testid": "recommended-product-title",
    lineHeight: 1.2
  }, props), "Sempre as", React.createElement(Text, _extends({
    as: "span",
    fontWeight: "bold",
    display: "inline"
  }, props), "\xA0melhores ofertas\xA0"), "com a", React.createElement(Text, _extends({
    as: "span",
    fontWeight: "bold",
    display: "inline"
  }, props), "\xA0entrega mais r\xE1pida\xA0"), "pra voc\xEA.");
};
export default RecommendedProductTitle;