import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
export var Container = styled(Box).attrs(function (props) {
  return _extends({
    alignItems: 'flex-end',
    lineHeight: 'normal',
    marginBottom: '12px',
    padding: '0px 14px',
    textAlign: 'left'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));