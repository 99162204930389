import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
import styled from 'styled-components';
import ButtonBase from '@magalu/stereo-ui/atoms/Button';
import Box from '@magalu/stereo-ui/atoms/Box';
import StereoText from '@magalu/stereo-ui/atoms/Text';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles/build/utils';
import LinkStereo from '@magalu/stereo-ui/atoms/Link';
var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  position: fixed;\n  z-index: ", ";\n  max-width: 1340px;\n  min-height: 60px;\n  border-top: 1px solid #eee;\n  border-radius: 4px;\n  box-shadow: 0 18px 25px rgb(0 0 0 / 10%);\n  background-color: ", ";\n  box-sizing: border-box;\n  padding: 1rem;\n  left: 50%;\n  ", ": 0;\n  transition: transform 0.4s;\n  transform: translateX(-50%)\n    translateY(\n      ", "\n    );\n"])), function (props) {
  return themeGet('zIndices.3')(props);
}, function (props) {
  return getPaletteColor('background.transparent')(props);
}, function (_ref) {
  var position = _ref.position;
  return position;
}, function (_ref2) {
  var position = _ref2.position,
    visible = _ref2.visible;
  var visibleValue = position === 'top' ? '-100%' : '100%';
  return visible ? '0' : visibleValue;
});
export var Text = styled(StereoText)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  font-size: ", "px;\n  line-height: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 60%;\n"])), function (props) {
  return themeGet('fontSizes.5')(props);
}, function (props) {
  return themeGet('lineHeights.fontSize')(props);
});
var BuyPrice = styled(Box).attrs(function () {
  return {
    width: ['50%', '50%', '20%', '20%']
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
var textBase = 'text.base';
var PriceType = styled(Box).attrs(function () {
  return {
    color: textBase,
    fontSize: [1, 1, 5, 5]
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  line-height: ", ";\n  overflow: hidden;\n"])), function (props) {
  return themeGet('lineHeights.fontSize')(props);
});
var PriceFeaturedComplement = styled.small(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  margin-left: 5px;\n"])), function (props) {
  return themeGet('fontSizes.1')(props);
});
var FloatBuyButton = styled(Box).attrs(function () {
  return {
    width: ['50%', '50%', '20%', '20%']
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
var ButtonFloat = styled(ButtonBase).attrs(function () {
  return {
    fontSize: [1, 1, 2, 2]
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-template-columns: unset;\n  height: 40px;\n  min-width: unset;\n"])));
var LinksContainer = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-template-columns: 200px 200px 200px;\n  padding: 20px 0px;\n"])));
var Link = styled(LinkStereo).attrs(function () {
  return {
    fontSize: 3
  };
})(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin-right: 6%;\n  text-decoration: ", ";\n  cursor: ", ";\n"])), function (_ref3) {
  var active = _ref3.active;
  return active ? 'none' : 'underline';
}, function (_ref4) {
  var active = _ref4.active;
  return active ? 'default' : 'pointer';
});
export { ButtonFloat, BuyPrice, Container, FloatBuyButton, Link, LinksContainer, PriceFeaturedComplement, PriceType };