import React from 'react';
import { bool } from 'prop-types';
import { Link, Flex, Text } from '@magalu/stereo-ui';
import { Icon } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import { IconTextContainer, Container, ContentText } from './ExchangeAndRefund.styles';
var MORE_INFO_LINK = 'https://www.magazineluiza.com.br/central-de-atendimento/duvidas-frequentes/troca-e-devolucao/';
var ExchangeAndRefund = function ExchangeAndRefund(_ref) {
  var isDeliveredByMagalu = _ref.isDeliveredByMagalu,
    isSoldByMagalu = _ref.isSoldByMagalu;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var getTitle = function getTitle() {
    var deliveryDescription = 'pelo Magalu';
    if (!isSoldByMagalu) {
      deliveryDescription = isDeliveredByMagalu ? 'por lojas parceiras' : 'e entregues por lojas parceiras';
    }
    return "Devolu\xE7\xE3o gratuita para produtos vendidos ".concat(deliveryDescription);
  };
  var onLinkClick = function onLinkClick() {
    publish('product:benefits:link:click', {
      eventLabel: 'politicas-de-trocas-e-devolucoes'
    });
  };
  return React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    gap: 2,
    mb: [3, 3, 4],
    pl: [0, 0, 3]
  }, React.createElement(Icon, {
    color: "background.magablu",
    name: "ExchangeCircle",
    width: 24,
    height: 24
  }), React.createElement(Text, {
    fontSize: 2,
    lineHeight: 1.2,
    fontWeight: "bold",
    color: "primary.base"
  }, getTitle())), React.createElement(Flex, {
    gap: 4,
    mb: 4,
    px: [0, 0, 2],
    flexDirection: "column"
  }, React.createElement(ContentText, {
    mx: ['0', '0', 'auto']
  }, "\xC9 simples", isSoldByMagalu ? ' trocar ou ' : ' ', "devolver seu produto, n\xE3o importa o motivo."), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "CloseCircleFilled",
    width: 24,
    height: 24
  }), React.createElement(ContentText, null, "Devolva seu produto em at\xE9 7 dias do recebimento")), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "DeliveryBox",
    color: "text.scratched"
  }), React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(ContentText, null, "Para devolver o produto consulte:"), React.createElement(Link, {
    target: "_blank",
    color: "primary.base",
    href: MORE_INFO_LINK,
    onClick: onLinkClick,
    underline: true
  }, "Pol\xEDticas de trocas e devolu\xE7\xF5es")))), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2,
    fontWeight: "bold"
  }, "Se tem no Magalu, t\xE1 garantido."), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2,
    fontWeight: "bold"
  }, "Pode confiar!"));
};
process.env.NODE_ENV !== "production" ? ExchangeAndRefund.propTypes = {
  isDeliveredByMagalu: bool,
  isSoldByMagalu: bool
} : void 0;
ExchangeAndRefund.defaultProps = {
  isDeliveredByMagalu: false,
  isSoldByMagalu: false
};
export default ExchangeAndRefund;