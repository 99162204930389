import React from 'react';
import CarouselComponent from '@magalu/stereo-ui/molecules/Carousel';
import { arrayOf, node, oneOfType, shape } from 'prop-types';
var Carousel = function Carousel(_ref) {
  var children = _ref.children,
    staticProps = _ref["static"];
  return React.createElement(CarouselComponent, staticProps, children);
};
Carousel.defaultProps = {
  "static": {}
};
process.env.NODE_ENV !== "production" ? Carousel.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  "static": shape({})
} : void 0;
Carousel.ssr = true;
export default Carousel;