import _extends from "@babel/runtime/helpers/esm/extends";
import _toArray from "@babel/runtime/helpers/esm/toArray";
import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Flex } from '@magalu/stereo-ui';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, MainContainer, Title, TagContainer, TextDetail } from './BenefitsCardDesktop.styles';
var MAX_WIDTH_PERCENTAGE = 100;
var BenefitsCardDesktop = function BenefitsCardDesktop(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var offers = staticProps.offers;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _data$product$offerTa = data.product.offerTags,
    offerTags = _data$product$offerTa === void 0 ? [] : _data$product$offerTa;
  React.useEffect(function () {
    publish('product:benefits:view', {
      labels: offers.map(function (offer) {
        return offer.label;
      }),
      offerTags: offerTags
    });
  }, [JSON.stringify(data)]);
  var _offers = _toArray(offers),
    restOffers = _offers.slice(1);
  var currentOffers = (offerTags == null ? void 0 : offerTags.length) === 0 || offerTags === null ? restOffers : offers;
  var handleOpenModal = function handleOpenModal(e, id, label) {
    e.preventDefault();
    publish("".concat(id, "modal:toggle"));
    publish('product:benefits:tags:click', label);
  };
  var containerWidth = MAX_WIDTH_PERCENTAGE / offers.length * currentOffers.length;
  return React.createElement(MainContainer, {
    borderRadius: "medium",
    width: "".concat(containerWidth.toFixed(2), "%")
  }, currentOffers.map(function (_ref2) {
    var description = _ref2.description,
      icon = _ref2.icon,
      id = _ref2.id,
      title = _ref2.title,
      label = _ref2.label;
    return React.createElement(Container, {
      key: id,
      onClick: function onClick(e) {
        return handleOpenModal(e, id, label);
      }
    }, React.createElement(Flex, {
      alignItems: "flex-start"
    }, React.createElement(Icon, _extends({
      color: "background.magablu"
    }, icon))), React.createElement(TagContainer, null, React.createElement(Title, {
      underline: true
    }, title), React.createElement(TextDetail, null, description)));
  }));
};
BenefitsCardDesktop.ssr = true;
process.env.NODE_ENV !== "production" ? BenefitsCardDesktop.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    offers: arrayOf(shape({
      description: string.isRequired,
      icon: string.isRequired,
      id: string.isRequired,
      label: string.isRequired,
      title: string.isRequired
    }))
  })
} : void 0;
BenefitsCardDesktop.defaultProps = {
  data: shape({}).isRequired,
  "static": {}
};
export default withLayoutProps(BenefitsCardDesktop);