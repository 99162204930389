import _typeof from "@babel/runtime/helpers/esm/typeof";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
import _inherits from "@babel/runtime/helpers/esm/inherits";
import _setPrototypeOf from "@babel/runtime/helpers/esm/setPrototypeOf";
var _excluded = ["brandPageUrl"];
function _wrapRegExp() { _wrapRegExp = function _wrapRegExp(re, groups) { return new BabelRegExp(re, void 0, groups); }; var _super = RegExp.prototype, _groups = new WeakMap(); function BabelRegExp(re, flags, groups) { var _this = new RegExp(re, flags); return _groups.set(_this, groups || _groups.get(re)), _setPrototypeOf(_this, BabelRegExp.prototype); } function buildGroups(result, re) { var g = _groups.get(re); return Object.keys(g).reduce(function (groups, name) { var i = g[name]; if ("number" == typeof i) groups[name] = result[i];else { for (var k = 0; void 0 === result[i[k]] && k + 1 < i.length;) { k++; } groups[name] = result[i[k]]; } return groups; }, Object.create(null)); } return _inherits(BabelRegExp, RegExp), BabelRegExp.prototype.exec = function (str) { var result = _super.exec.call(this, str); if (result) { result.groups = buildGroups(result, this); var indices = result.indices; indices && (indices.groups = buildGroups(indices, this)); } return result; }, BabelRegExp.prototype[Symbol.replace] = function (str, substitution) { if ("string" == typeof substitution) { var groups = _groups.get(this); return _super[Symbol.replace].call(this, str, substitution.replace(/\$<([^>]+)>/g, function (_, name) { var group = groups[name]; return "$" + (Array.isArray(group) ? group.join("$") : group); })); } if ("function" == typeof substitution) { var _this = this; return _super[Symbol.replace].call(this, str, function () { var args = arguments; return "object" != _typeof(args[args.length - 1]) && (args = [].slice.call(args)).push(buildGroups(args, _this)), substitution.apply(this, args); }); } return _super[Symbol.replace].call(this, str, substitution); }, _wrapRegExp.apply(this, arguments); }
import React from 'react';
import { arrayOf, number, string, shape } from 'prop-types';
import TableFactsheet from '@magalu/stereo-ui/molecules/TableFactsheet';
import Button from '@magalu/stereo-ui/atoms/Button';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useProductInfo } from '../../../hooks';
import { Content } from './Factsheet.styles';
var aHrefRegexp = _wrapRegExp(/<a[^<]*href=['"](\S+)['"]/, {
  url: 1
});
var getUrlFromHTML = function getUrlFromHTML(data) {
  var _data$match, _data$match$groups, _data$match$groups$ur;
  return ((_data$match = data.match(aHrefRegexp)) == null ? void 0 : (_data$match$groups = _data$match.groups) == null ? void 0 : (_data$match$groups$ur = _data$match$groups.url) == null ? void 0 : _data$match$groups$ur.replace(/http:\/\//g, 'https://')) || null;
};
var parseManualUrl = function parseManualUrl(data) {
  if (!data) return null;
  if (typeof data === 'string') return getUrlFromHTML(data);
  if (Array.isArray(data)) return parseManualUrl(data[0]);
  return parseManualUrl((data == null ? void 0 : data.elements) || (data == null ? void 0 : data.value));
};
var getManualUrl = function getManualUrl(factSheetBySlug) {
  var _factSheetBySlug$fich, _factSheetBySlug$fich2;
  return parseManualUrl(factSheetBySlug.manual || ((_factSheetBySlug$fich = factSheetBySlug['ficha-tecnica']) == null ? void 0 : (_factSheetBySlug$fich2 = _factSheetBySlug$fich.elements) == null ? void 0 : _factSheetBySlug$fich2.find(function (element) {
    var _element$keyName, _element$keyName$toLo, _element$elements, _element$elements$;
    return (element == null ? void 0 : (_element$keyName = element.keyName) == null ? void 0 : (_element$keyName$toLo = _element$keyName.toLowerCase()) == null ? void 0 : _element$keyName$toLo.includes('manual')) && (element == null ? void 0 : (_element$elements = element.elements) == null ? void 0 : (_element$elements$ = _element$elements[0]) == null ? void 0 : _element$elements$.isHtml);
  })));
};
var parseElementValue = function parseElementValue(element, parent, _ref) {
  var basePath = _ref.basePath,
    brandPageUrl = _ref.brandPageUrl,
    brand = _ref.brand;
  if ((element == null ? void 0 : element.slug) === 'marca' || parent.slug === 'marca') {
    return _extends({}, element, {
      isLink: true,
      path: "".concat(basePath).concat(brandPageUrl).concat(brand == null ? void 0 : brand.slug, "/")
    });
  }
  if (element == null ? void 0 : element.isHtml) return null;
  return element;
};
var Factsheet = function Factsheet(_ref2) {
  var _ref3;
  var data = _ref2.data,
    staticProps = _ref2["static"],
    structure = _ref2.structure;
  var _structure$basePath = structure.basePath,
    basePath = _structure$basePath === void 0 ? '' : _structure$basePath;
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product),
    factsheet = _useProductInfo.factsheet,
    brand = _useProductInfo.brand;
  var brandPageUrl = staticProps.brandPageUrl,
    props = _objectWithoutProperties(staticProps, _excluded);
  if (!factsheet) return null;
  var factSheetBySlug = factsheet.reduce(function (sheet, item) {
    return _extends({}, sheet, _defineProperty({}, item.slug, item));
  }, {});
  var fallbackData = {
    elements: []
  };
  var factData = factSheetBySlug['ficha-tecnica'] || fallbackData;
  var packageContent = factSheetBySlug['conteudo-da-embalagem'] || fallbackData;
  var parsedElements = (_ref3 = [].concat(_toConsumableArray(factData.elements), _toConsumableArray(packageContent.elements))) == null ? void 0 : _ref3.reduce(function (items, factSheetItem) {
    var _factSheetItem$elemen;
    var elements = factSheetItem == null ? void 0 : (_factSheetItem$elemen = factSheetItem.elements) == null ? void 0 : _factSheetItem$elemen.reduce(function (factSheetElems, element) {
      var parsedElement = parseElementValue(element, factSheetItem, {
        basePath: basePath,
        brand: brand,
        brandPageUrl: brandPageUrl
      });
      return parsedElement ? [].concat(_toConsumableArray(factSheetElems), [parsedElement]) : factSheetElems;
    }, []);
    return (elements == null ? void 0 : elements.length) ? [].concat(_toConsumableArray(items), [_extends({}, factSheetItem, {
      elements: elements,
      slug: factSheetItem == null ? void 0 : factSheetItem.keyName
    })]) : items;
  }, []);
  var parsedFactData = _extends({}, factData, {
    elements: parsedElements
  });
  var hasFactSheet = parsedElements.length;
  var manualUrl = getManualUrl(factSheetBySlug);
  return (hasFactSheet || manualUrl) && React.createElement(Content, null, hasFactSheet && React.createElement(TableFactsheet, _extends({
    data: parsedFactData
  }, props)), manualUrl && React.createElement(Button, {
    "data-testid": "manual-download",
    as: "a",
    href: manualUrl,
    target: "_blank",
    uppercase: false,
    size: "medium",
    width: "200px",
    startIcon: "Rules",
    mt: 2
  }, "Baixe o manual"));
};
Factsheet.defaultProps = {
  data: {
    product: {}
  },
  "static": {
    brandPageUrl: '/marcas/',
    fontSize: 2,
    layout: 'horizontal'
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? Factsheet.propTypes = {
  data: shape({
    product: shape({
      factsheet: arrayOf(shape({}))
    })
  }),
  "static": shape({
    brandPageUrl: string,
    fontSize: number,
    layout: string
  }),
  structure: shape({})
} : void 0;
Factsheet.dataSource = {
  product: {
    fragments: ['includeFactSheet']
  }
};
Factsheet.ssr = true;
export default withLayoutProps(Factsheet);