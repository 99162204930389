import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
var _excluded = ["events", "label", "endIcon", "startIcon"];
import React from 'react';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import StereoChip from '@magalu/stereo-ui/atoms/Chip';
import { usePublisher } from '@magalu/mixer-publisher';
var getIcons = function getIcons() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    endIcon = _ref.endIcon,
    startIcon = _ref.startIcon;
  return _extends({}, (endIcon == null ? void 0 : endIcon.name) && {
    endIcon: React.createElement(Icon, endIcon)
  }, (startIcon == null ? void 0 : startIcon.name) && {
    startIcon: React.createElement(Icon, startIcon)
  });
};
var Chip = function Chip(_ref2) {
  var _ref2$static = _ref2["static"],
    staticProps = _ref2$static === void 0 ? {} : _ref2$static;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _staticProps$events = staticProps.events,
    events = _staticProps$events === void 0 ? [] : _staticProps$events,
    label = staticProps.label,
    endIcon = staticProps.endIcon,
    startIcon = staticProps.startIcon,
    props = _objectWithoutProperties(staticProps, _excluded);
  var onClickChip = function onClickChip() {
    events.forEach(function (_ref3) {
      var eventName = _ref3.eventName,
        eventArgs = _ref3.eventArgs;
      if (eventName) publish(eventName, eventArgs);
    });
  };
  return React.createElement(React.Fragment, null, label && React.createElement(StereoChip, _extends({}, getIcons({
    endIcon: endIcon,
    startIcon: startIcon
  }), {
    onClick: onClickChip
  }, props), label));
};
process.env.NODE_ENV !== "production" ? Chip.propTypes = {
  "static": shape({
    autoSize: bool,
    bg: string,
    borderRadius: oneOfType([number, string]),
    endIcon: shape({
      fill: string,
      height: string,
      name: string,
      width: string
    }),
    events: arrayOf(shape({
      eventArgs: shape({}),
      eventName: string.isRequired
    })),
    fontSize: oneOfType([number, string]),
    fontWeight: oneOfType([number, string]),
    gridGap: oneOfType([number, string]),
    label: string,
    padding: oneOfType([number, string]),
    startIcon: shape({
      fill: string,
      height: string,
      name: string,
      width: string
    }),
    width: string
  })
} : void 0;
Chip.defaultProps = {
  "static": {}
};
Chip.ssr = true;
export default Chip;