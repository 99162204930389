import React from 'react';
import { shape, bool } from 'prop-types';
import DeliveryGuaranteeComponent from '@magalu/stereo-ui/organisms/DeliveryGuarantee';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { handleText, handleTitle } from './DeliveryGuarantee.utils';
var DeliveryGuarantee = function DeliveryGuarantee(_ref) {
  var product = _ref.data.product,
    _ref$static = _ref["static"],
    showDialog = _ref$static.showDialog,
    isMagaluCompany = _ref$static.isMagaluCompany,
    isDefaultDelivery = _ref$static.isDefaultDelivery;
  var seller = product.seller;
  var props = {
    detailsText: handleText(isMagaluCompany, isDefaultDelivery, seller.description),
    detailsTitle: handleTitle(isMagaluCompany, isDefaultDelivery, seller.description),
    isMagaluCompany: isMagaluCompany,
    seller: seller,
    showDialog: showDialog
  };
  return React.createElement(DeliveryGuaranteeComponent, props);
};
process.env.NODE_ENV !== "production" ? DeliveryGuarantee.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    isDefaultDelivery: bool,
    isMagaluCompany: bool,
    showDialog: bool
  })
} : void 0;
DeliveryGuarantee.defaultProps = {
  data: {},
  "static": {
    isDefaultDelivery: false,
    isMagaluCompany: false,
    showDialog: false
  }
};
DeliveryGuarantee.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
DeliveryGuarantee.ssr = true;
export default withLayoutProps(DeliveryGuarantee);