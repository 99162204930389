import React from 'react';
import { string, shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Image } from './BannerLink.styles';
var BannerLink = function BannerLink(_ref) {
  var staticProps = _ref["static"];
  var alt = staticProps.alt,
    title = staticProps.title,
    link = staticProps.link,
    image = staticProps.image,
    targetBlank = staticProps.targetBlank;
  if (image) {
    return React.createElement("a", {
      "data-testid": "banner-link",
      href: link,
      title: title,
      target: targetBlank ? '_blank' : '_self',
      rel: "noreferrer"
    }, React.createElement(Image, {
      src: image,
      alt: alt,
      lazy: true
    }));
  }
  return null;
};
BannerLink.defaultProps = {
  "static": {
    alt: '',
    image: '',
    link: '',
    targetBlank: false,
    title: ''
  }
};
process.env.NODE_ENV !== "production" ? BannerLink.propTypes = {
  "static": shape({
    alt: string.isRequired,
    image: string.isRequired,
    link: string.isRequired,
    title: string.isRequired
  })
} : void 0;
BannerLink.ssr = true;
export default withLayoutProps(BannerLink);