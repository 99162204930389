import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    borderTop: 3,
    color: 'text.base',
    lineHeight: 'big',
    padding: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));