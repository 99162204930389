import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { node, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import Container from '@magalu/stereo-ui/atoms/Flex';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { bannerClickedKey } from './constants';
var CloseableAdUnit = function CloseableAdUnit(_ref) {
  var staticProps = _ref["static"],
    children = _ref.children;
  var bannerClosed = function bannerClosed() {
    return sessionStorage.getItem(bannerClickedKey);
  };
  var _React$useState = React.useState(bannerClosed),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    closed = _React$useState2[0],
    closedSet = _React$useState2[1];
  var _React$useState3 = React.useState(false),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    loaded = _React$useState4[0],
    loadedSet = _React$useState4[1];
  var iconName = staticProps.iconName,
    bannerId = staticProps.bannerId;
  var _children = _slicedToArray(children, 1),
    child = _children[0];
  var handleIconClick = function handleIconClick() {
    closedSet(true);
    sessionStorage.setItem(bannerClickedKey, true);
  };
  var handleSlotOnLoad = function handleSlotOnLoad(_ref2) {
    var slot = _ref2.slot;
    var _ref3 = (slot == null ? void 0 : slot.getSlotElementId()) || [],
      _ref4 = _slicedToArray(_ref3, 2),
      slotId = _ref4[0],
      bannerRef = _ref4[1];
    if (slotId == null ? void 0 : slotId.includes(bannerId)) {
      var _bannerRef$current;
      loadedSet(!!(bannerRef == null ? void 0 : (_bannerRef$current = bannerRef.current) == null ? void 0 : _bannerRef$current.hasChildNodes()));
    }
  };
  var Banner = function Banner() {
    if (!child) return null;
    return React.cloneElement(child, {
      slotOnload: handleSlotOnLoad
    });
  };
  return !closed ? React.createElement(Container, {
    justifyContent: "start",
    px: 1,
    pt: "0px",
    pb: "1px",
    bg: "background.base",
    maxHeight: "90px",
    overflow: "hidden",
    gap: 1,
    display: loaded ? 'flex' : 'none',
    "data-testid": "closeable-adunit"
  }, React.createElement(Container, {
    alignItems: "center",
    justifyContent: "center",
    onClick: handleIconClick
  }, React.createElement(Icon, {
    name: iconName,
    color: "text.attributeLabel",
    fill: "text.attributeLabel",
    title: "Fechar",
    role: "button"
  })), React.createElement(Banner, null)) : React.createElement(React.Fragment, null);
};
process.env.NODE_ENV !== "production" ? CloseableAdUnit.propTypes = {
  children: node,
  "static": shape({
    bannerId: string,
    iconName: string
  })
} : void 0;
CloseableAdUnit.defaultProps = {
  children: [],
  "static": {
    iconName: 'Close'
  }
};
CloseableAdUnit.ssr = false;
CloseableAdUnit.displayName = 'CloseableAdUnit';
export default withLayoutProps(CloseableAdUnit);