import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect } from 'react';
import { number, shape, string } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import Link from '@magalu/stereo-ui/atoms/Link';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
import { termToUrl } from '@magalu/mixer-structure';
import { Container } from './DidYouMean.styles';
var DidYouMean = function DidYouMean(_ref) {
  var _data$search, _data$search$term, _data$search2, _data$search2$term, _data$search3, _data$search3$term, _data$search4, _data$search4$term;
  var data = _ref.data,
    staticProps = _ref["static"];
  var label = staticProps.label,
    fontSize = staticProps.fontSize,
    fontWeight = staticProps.fontWeight;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var eventData = {
    raw: data == null ? void 0 : (_data$search = data.search) == null ? void 0 : (_data$search$term = _data$search.term) == null ? void 0 : _data$search$term.raw,
    refined: data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : (_data$search2$term = _data$search2.term) == null ? void 0 : _data$search2$term.refined
  };
  useEffect(function () {
    publish('didyoumean:view', _extends({}, eventData));
  }, []);
  var href = "/busca/".concat(termToUrl(data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : (_data$search3$term = _data$search3.term) == null ? void 0 : _data$search3$term.raw), "/?ignoreSpellCheck=true");
  return (data == null ? void 0 : (_data$search4 = data.search) == null ? void 0 : (_data$search4$term = _data$search4.term) == null ? void 0 : _data$search4$term.raw) ? React.createElement(Container, null, React.createElement(Heading, {
    as: "span",
    color: "base",
    paddingRight: "4px",
    fontWeight: fontWeight,
    "data-testid": "did-you-mean",
    fontSize: fontSize
  }, label), React.createElement(Link, {
    href: href,
    "data-testid": "term-did-you-mean",
    underline: true,
    spa: true,
    color: "background.primary",
    onClick: function onClick() {
      return publish('didyoumean:click', _extends({}, eventData));
    },
    fontWeight: "medium",
    cursor: "pointer",
    fontSize: fontSize
  }, data.search.term.raw)) : null;
};
process.env.NODE_ENV !== "production" ? DidYouMean.propTypes = {
  data: shape({}),
  "static": shape({
    fontSize: number,
    fontWeight: string,
    label: string
  })
} : void 0;
DidYouMean.defaultProps = {
  data: {},
  "static": {
    fontSize: 2,
    fontWeight: 'medium',
    label: 'Em vez disso, pesquisar por'
  }
};
DidYouMean.ssr = true;
export default withLayoutProps(DidYouMean);