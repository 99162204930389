import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { themeGet } from '@magalu/stereo-ui-styles';
import Box from '@magalu/stereo-ui/atoms/Box';
export var Container = styled(Box).attrs(function (props) {
  return _extends({}, props, {
    borderRadius: 'default',
    margin: 2
  });
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: ", ";\n"])), themeGet('borders.0'));
export var Title = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  color: ", ";\n  line-height: ", ";\n  margin-right: 0.8ch;\n"])), themeGet('fontWeights.bold'), themeGet('colors.darkSilver'), function (props) {
  return themeGet('lineHeights.display')(props);
});