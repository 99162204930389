import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var buildMagaluCompanyText = function buildMagaluCompanyText(isDefaultDelivery, sellerName) {
  var defaultDeliveryText = ["O Magalu garante e faz a entrega deste produto vendido pela ".concat(sellerName, " at\xE9 o seu destino."), "A ".concat(sellerName, " \xE9 um empresa do Grupo Magalu e compartilha do nosso prop\xF3sito, oferecendo uma experi\xEAncia de compra diferenciada, com excel\xEAncia no atendimento e calor humano.")];
  var customDeliveryText = ['O Magalu garante e acompanha o envio deste produto vendido e entregue por uma empresa do Grupo Magalu até o seu destino.', 'Todas as empresas do nosso ecossistema compartilham do nosso propósito, oferecendo uma experiência de compra diferenciada, com excelência no atendimento e calor humano.'];
  return [].concat(_toConsumableArray(isDefaultDelivery ? defaultDeliveryText : customDeliveryText), ['Além disso, certificamos as vendas com nota fiscal, e também garantimos a procedência de produtos originais. Se faz parte do Grupo Magalu tá garantido, pode confiar!']);
};
var buildPartnerText = function buildPartnerText(isDefaultDelivery) {
  var defaultDeliveryText = 'Este produto é vendido por um lojista parceiro, e o Magalu garante e faz a entrega até o seu destino!';
  var customDeliveryText = 'Este produto é vendido e entregue por um lojista parceiro, e o Magalu garante e acompanha o envio até o seu destino!';
  return [isDefaultDelivery ? defaultDeliveryText : customDeliveryText, 'Todos os nossos parceiros são selecionados e avaliados, de tempos em tempos, por critérios de excelência no serviço e satisfação dos clientes.', 'Além disso, certificamos as vendas com nota fiscal, e garantimos a procedência de produtos originais. Se tem no Magalu, tá garantido! Pode confiar!'];
};
export var handleText = function handleText(isMagaluCompany, isDefaultDelivery, sellerName) {
  return isMagaluCompany ? buildMagaluCompanyText(isDefaultDelivery, sellerName) : buildPartnerText(isDefaultDelivery);
};
export var handleTitle = function handleTitle(isMagaluCompany, isDefaultDelivery, sellerName) {
  if (isMagaluCompany) {
    return isDefaultDelivery ? "Produto vendido por ".concat(sellerName, " e entregue pelo Magalu.") : 'Produto vendido e entregue por empresa do Grupo Magalu.';
  }
  return isDefaultDelivery ? 'Produto vendido por uma loja parceira e entregue pelo Magalu.' : 'Produto vendido e entregue por uma loja parceira.';
};