import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import { Flex, Text, Link } from '@magalu/stereo-ui';
import { getBackgroundColor } from '@magalu/stereo-ui-styles/build/utils';
export var Container = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex: 1 1 0;\n  padding: 24px;\n  gap: 24px;\n  cursor: pointer;\n"])));
export var MainContainer = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border: 2px solid ", ";\n"])), function (props) {
  return getBackgroundColor('darker')(props);
});
export var TagContainer = styled(Flex)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex-direction: column;\n"])));
export var TextDetail = styled(Text).attrs(function () {
  return {
    fontSize: 1,
    fontWeight: 'regular',
    lineHeight: 'display'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var Title = styled(Link).attrs(function (props) {
  return _extends({
    color: 'text.primary',
    fontSize: 1,
    fontWeight: 'bold',
    pb: 2
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  text-align: left;\n"])));