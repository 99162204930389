import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label"];
import React from 'react';
import { arrayOf, shape } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Label, Description } from './BestInstallment.styles';
var BestInstallment = function BestInstallment(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _data$product = data == null ? void 0 : data.product,
    installment = _data$product.installment;
  var label = staticProps.label,
    props = _objectWithoutProperties(staticProps, _excluded);
  var handleClick = function handleClick() {
    publish('product:paymentmethod:click', {});
  };
  var paymentMethodDescription = installment.paymentMethodDescription,
    totalAmount = installment.totalAmount,
    quantity = installment.quantity,
    amount = installment.amount;
  var total = currencyFormatter({
    value: totalAmount
  });
  var value = currencyFormatter({
    value: amount
  });
  return React.createElement(Box, _extends({
    border: 0,
    borderRadius: "medium",
    as: "section",
    padding: 3
  }, props, {
    onClick: handleClick,
    role: "button",
    "aria-hidden": true,
    "aria-label": "ver m\xE9todos de pagamento",
    style: {
      cursor: 'pointer'
    }
  }), React.createElement(Flex, {
    justifyContent: "space-between"
  }, React.createElement("div", null, React.createElement(Label, null, label), React.createElement(Description, null, paymentMethodDescription)), React.createElement("div", null, React.createElement(Description, {
    textAlign: "right"
  }, total), React.createElement(Description, {
    fontWeight: "500"
  }, "".concat(quantity, "x").concat(value)))));
};
process.env.NODE_ENV !== "production" ? BestInstallment.propTypes = {
  data: shape({
    product: shape({
      installment: shape({}),
      paymentMethods: arrayOf(shape({}))
    })
  }),
  "static": shape({})
} : void 0;
BestInstallment.defaultProps = {
  data: {},
  "static": {
    label: 'Cartão de crédito'
  }
};
BestInstallment.ssr = true;
BestInstallment.displayName = 'BestInstallment';
BestInstallment.dataSource = {
  product: {
    fragments: ['showPayment'],
    query: 'ProductQuery'
  }
};
export default withLayoutProps(BestInstallment);