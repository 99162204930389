import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Title, DialogStyled, InfoText, Button, ContainerHeader, CloseButton, ContainerActions } from './ConfirmDialog.styles';
var ConfirmDialog = function ConfirmDialog(props) {
  var infoText = props.infoText,
    confirmAction = props.confirmAction,
    title = props.title,
    confirmText = props.confirmText,
    open = props.open,
    onClose = props.onClose,
    headerComponent = props.headerComponent;
  return React.createElement(DialogStyled, {
    position: "center",
    type: "responsive",
    showClose: false,
    visible: open,
    onClickOutside: onClose
  }, React.createElement(CloseButton, {
    role: "presentation",
    "data-testid": "ConfirmDialog-close-button",
    onClick: onClose
  }, React.createElement(Icon, {
    name: "Close",
    width: 24,
    height: 24
  })), headerComponent && React.createElement(ContainerHeader, null, headerComponent), React.createElement(Title, {
    "data-testid": "ConfirmDialog-title"
  }, title), React.createElement(InfoText, {
    "data-testid": "ConfirmDialog-infoText"
  }, infoText), React.createElement(ContainerActions, null, React.createElement(Button, {
    "data-testid": "ConfirmDialog-redirect-button",
    type: "button",
    onClick: confirmAction
  }, confirmText)));
};
process.env.NODE_ENV !== "production" ? ConfirmDialog.propTypes = {
  confirmAction: func.isRequired,
  confirmText: string,
  headerComponent: node,
  infoText: string.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  title: string.isRequired
} : void 0;
ConfirmDialog.defaultProps = {
  confirmText: 'Ok',
  headerComponent: undefined
};
ConfirmDialog.ssr = false;
ConfirmDialog.displayName = 'ConfirmDialog';
export default withLayoutProps(ConfirmDialog);