import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["url", "linkText"];
import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Link } from './Complaint.styles';
var Complaint = function Complaint(_ref) {
  var _data$product, _data$product2;
  var StaticProps = _ref["static"],
    structure = _ref.structure,
    data = _ref.data;
  var url = StaticProps.url,
    linkText = StaticProps.linkText,
    props = _objectWithoutProperties(StaticProps, _excluded);
  var productURL = "".concat(structure.config.domain, "/").concat(data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.url);
  var destiny = "".concat(url, "/").concat(data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.variationId, "/?from=").concat(productURL);
  var onHandleClick = function onHandleClick(e) {
    e.preventDefault();
    routePush({
      path: destiny,
      spa: true
    });
  };
  return React.createElement(Box, _extends({
    p: 3
  }, props), React.createElement(Link, {
    "data-testid": "complaint-link",
    href: destiny,
    onClick: onHandleClick
  }, linkText));
};
process.env.NODE_ENV !== "production" ? Complaint.propTypes = {
  data: shape({
    product: shape({
      variationId: string
    })
  }),
  "static": shape({
    linkText: string,
    url: string
  }),
  structure: shape({
    config: shape({
      canonicalDomain: string,
      domain: string
    })
  })
} : void 0;
Complaint.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
Complaint.ssr = true;
Complaint.displayName = 'Complaint';
export default withLayoutProps(Complaint);