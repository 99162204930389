import React from 'react';
import { bool } from 'prop-types';
import { Flex } from '@magalu/stereo-ui';
import { Icon } from '@magalu/stereo-ui-icons';
import { Container, IconTextContainer, Text } from './DeliveryAssurance.styles';
var DeliveryAssurance = function DeliveryAssurance(_ref) {
  var isDeliveredByMagalu = _ref.isDeliveredByMagalu,
    isSoldByMagalu = _ref.isSoldByMagalu;
  var getTitle = function getTitle() {
    if (isSoldByMagalu) {
      return 'Produto vendido e entregue pelo Magalu';
    }
    if (isDeliveredByMagalu) {
      return 'Produto vendido por loja parceira e entregue pelo Magalu';
    }
    return 'Produto vendido e entregue por loja parceira';
  };
  return React.createElement(Container, null, React.createElement(Flex, {
    alignItems: "center",
    gap: 2,
    mb: 3
  }, React.createElement(Icon, {
    name: "GuaranteeFilled"
  }), React.createElement(Text, {
    fontSize: 2,
    fontWeight: "bold",
    color: "primary.base"
  }, getTitle())), React.createElement(Flex, {
    gap: 4,
    mb: 4,
    px: [0, 0, 2],
    mt: !isSoldByMagalu ? [0, 0, 2] : 0,
    flexDirection: "column"
  }, !isSoldByMagalu && React.createElement(IconTextContainer, {
    "data-testid": "responsability-text"
  }, React.createElement(Icon, {
    name: "FastDelivery",
    color: "text.scratched"
  }), React.createElement(Text, null, "O Magalu se responsabiliza pela entrega dos produtos de parceiros at\xE9 o", React.createElement(Text, {
    fontWeight: "bold"
  }, " destino final. "))), React.createElement(IconTextContainer, {
    "data-testid": "receipt-text"
  }, React.createElement(Icon, {
    name: "Receipt",
    color: "text.scratched",
    width: 24,
    height: 20
  }), React.createElement(Text, null, "As vendas s\xE3o", React.createElement(Text, {
    fontWeight: "bold"
  }, " certificadas "), "com nota fiscal e garantimos a proced\xEAncia de produtos originais.")), !isSoldByMagalu && React.createElement(IconTextContainer, {
    "data-testid": "excellence-text"
  }, React.createElement(Icon, {
    name: "Star",
    color: "text.scratched"
  }), React.createElement(Text, null, "Os parceiros s\xE3o selecionados e", React.createElement(Text, {
    fontWeight: "bold"
  }, "\xA0avaliados"), "\xA0regularmente portal", React.createElement(Text, {
    fontWeight: "bold"
  }, "\xA0excel\xEAncia no servi\xE7o"), "\xA0e reputa\xE7\xE3o com os clientes."))), React.createElement(Text, {
    fontWeight: "bold"
  }, "Se tem no Magalu, t\xE1 garantido."), React.createElement(Text, {
    fontWeight: "bold"
  }, "Pode confiar!"));
};
process.env.NODE_ENV !== "production" ? DeliveryAssurance.propTypes = {
  isDeliveredByMagalu: bool,
  isSoldByMagalu: bool
} : void 0;
DeliveryAssurance.defaultProps = {
  isDeliveredByMagalu: false,
  isSoldByMagalu: false
};
export default DeliveryAssurance;