import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import { Text, Flex, Button as StereoButton } from '@magalu/stereo-ui/atoms';
export var Button = styled(StereoButton).attrs(function (props) {
  return _extends({
    backgroundColor: 'white'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Content = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
    height: '260px',
    justifyContent: 'space-between',
    marginBottom: 2,
    maxHeight: '260px',
    px: 1,
    py: 4
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var InfoText = styled(Text).attrs(function (props) {
  return _extends({
    color: 'text.light',
    fontSize: 3,
    lineHeight: 'display',
    maxWidth: '650px',
    textAlign: 'center',
    width: '100%'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    color: 'secondary.base',
    fontSize: 6,
    fontWeight: 'bold',
    lineHeight: 'display',
    textAlign: 'center'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));