import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import React from 'react';
import { shape, arrayOf } from 'prop-types';
import Hr from '@magalu/stereo-ui/atoms/HorizontalRule';
import List from '@magalu/stereo-ui/molecules/List';
import { routePush, getCurrentPath } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var CustomPagination = function CustomPagination(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var _staticProps$values = staticProps.values,
    values = _staticProps$values === void 0 ? [] : _staticProps$values,
    paginationKey = staticProps.paginationKey;
  var _structure$route = structure.route,
    route = _structure$route === void 0 ? {} : _structure$route;
  var urlParam = route[paginationKey];
  var buildHref = function buildHref(value) {
    return getCurrentPath(_defineProperty({}, paginationKey, value)) || "".concat(structure.basePath).concat(structure.asPath, "?").concat(paginationKey, "=").concat(value || 'A');
  };
  var charList = function charList(from, to) {
    var a = from.charCodeAt();
    var z = to.charCodeAt();
    return _toConsumableArray(Array(Math.floor(z - a) + 1)).map(function (_, i) {
      return {
        label: String.fromCharCode(a + i),
        value: String.fromCharCode(a + i)
      };
    });
  };
  var reduceValues = function reduceValues(_ref2) {
    var from = _ref2.from,
      to = _ref2.to,
      label = _ref2.label,
      value = _ref2.value;
    if (from && to) {
      return charList(from, to);
    }
    return {
      label: label,
      value: value
    };
  };
  var flattenValues = values.flatMap(reduceValues);
  var changeRoute = function changeRoute(value, e) {
    e.preventDefault();
    routePush({
      path: getCurrentPath(_defineProperty({
        page: 1
      }, paginationKey, value)),
      spa: true
    });
  };
  var PaginationItems = flattenValues.map(function (_ref3) {
    var label = _ref3.label,
      value = _ref3.value;
    return React.createElement(List.Item, {
      as: "a",
      key: value,
      href: buildHref(value),
      onClick: function onClick(e) {
        return changeRoute(value, e);
      },
      color: urlParam === value ? 'background.primary' : null,
      fontWeight: urlParam === value ? 'bold' : 'light'
    }, label == null ? void 0 : label.toUpperCase());
  });
  return React.createElement(React.Fragment, null, React.createElement(Hr, null), React.createElement(List, {
    horizontal: true
  }, PaginationItems), React.createElement(Hr, null));
};
process.env.NODE_ENV !== "production" ? CustomPagination.propTypes = {
  "static": shape({
    values: arrayOf(shape({}))
  }),
  structure: shape({
    route: shape({})
  })
} : void 0;
CustomPagination.defaultProps = {
  "static": {},
  structure: {}
};
CustomPagination.ssr = true;
export default withLayoutProps(CustomPagination);