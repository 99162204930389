import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { List } from '@magalu/stereo-ui/molecules';
import { Icon } from '@magalu/stereo-ui-icons';
import { Box, Flex, Text } from '@magalu/stereo-ui/atoms';
import { usePublisher } from '@magalu/mixer-publisher';
import { Title, Container } from './BenefitsCardMobile.styles';
var BenefitsCardMobile = function BenefitsCardMobile(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var offers = staticProps.offers;
  var _data$product$offerTa = data.product.offerTags,
    offerTags = _data$product$offerTa === void 0 ? [] : _data$product$offerTa;
  React.useEffect(function () {
    publish('product:benefits:view', {
      labels: offers.map(function (offer) {
        return offer.label;
      }),
      offerTags: offerTags
    });
  }, [JSON.stringify(data)]);
  var handleClick = function handleClick(e, id, label) {
    e.preventDefault();
    publish("".concat(id, "modal:toggle"));
    publish('product:benefits:tags:click', label);
  };
  var filteredOffers = offers == null ? void 0 : offers.filter(function (item) {
    return !(item.id === 'recommendedproduct' && !(offerTags == null ? void 0 : offerTags.includes('magalu_indica')));
  });
  return React.createElement(Container, null, React.createElement(List, {
    scale: "large"
  }, filteredOffers == null ? void 0 : filteredOffers.map(function (_ref2) {
    var description = _ref2.description,
      icon = _ref2.icon,
      id = _ref2.id,
      title = _ref2.title,
      label = _ref2.label;
    return React.createElement(List.Item, {
      key: id,
      endIcon: "ChevronRight",
      fill: "background.magablu",
      "data-testid": "benefits-list",
      onClick: function onClick(e) {
        return handleClick(e, id, label);
      }
    }, React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Box, {
      paddingRight: 2
    }, React.createElement(Icon, {
      name: icon,
      color: "background.magablu",
      width: filteredOffers.length > 2 ? 40 : 24,
      height: 24
    })), React.createElement(Box, null, React.createElement(Text, {
      fontSize: 1,
      color: "text.scratched",
      marginTop: 1
    }, React.createElement(Title, null, title), description))));
  })));
};
process.env.NODE_ENV !== "production" ? BenefitsCardMobile.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    offers: arrayOf(shape({
      description: string.isRequired,
      icon: string.isRequired,
      id: string.isRequired,
      label: string.isRequired,
      title: string.isRequired
    }))
  })
} : void 0;
BenefitsCardMobile.defaultProps = {
  data: shape({}).isRequired,
  "static": {}
};
BenefitsCardMobile.ssr = true;
export default BenefitsCardMobile;