import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, node, oneOfType, shape } from 'prop-types';
import Carousel from '@magalu/stereo-ui/molecules/Carousel';
import withLayoutProps from '../../../hocs/withLayoutProps';
var CarouselAdUnit = function CarouselAdUnit(_ref) {
  var children = _ref.children,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    adUnits = _useState2[0],
    setAdUnits = _useState2[1];
  var hasAdsContent = function hasAdsContent(_ref2, mobile) {
    var _current$getElementsB;
    var current = _ref2.current;
    var tag = mobile ? 'div' : 'iframe';
    return current == null ? void 0 : (_current$getElementsB = current.getElementsByTagName(tag)) == null ? void 0 : _current$getElementsB.length;
  };
  var handleSlotOnLoad = useCallback(function (_ref3) {
    var slot = _ref3.slot;
    var _slot$getSlotElementI = slot.getSlotElementId(),
      _slot$getSlotElementI2 = _slicedToArray(_slot$getSlotElementI, 3),
      adUnitID = _slot$getSlotElementI2[0],
      ref = _slot$getSlotElementI2[1],
      mobile = _slot$getSlotElementI2[2];
    setAdUnits(function (current) {
      return current.map(function (_ref4) {
        var _module$props, _module$props$slice, _module$props$slice$s;
        var module = _ref4.module,
          isEmpty = _ref4.isEmpty;
        if (adUnitID == null ? void 0 : adUnitID.includes((_module$props = module.props) == null ? void 0 : (_module$props$slice = _module$props.slice) == null ? void 0 : (_module$props$slice$s = _module$props$slice["static"]) == null ? void 0 : _module$props$slice$s.path)) {
          return {
            isEmpty: isEmpty || !hasAdsContent(ref, mobile),
            module: module
          };
        }
        return {
          isEmpty: isEmpty,
          module: module
        };
      });
    });
  }, [adUnits]);
  useEffect(function () {
    var _staticProps$limits, _staticProps$limitId;
    var limit = (staticProps == null ? void 0 : (_staticProps$limits = staticProps.limits) == null ? void 0 : _staticProps$limits[staticProps == null ? void 0 : (_staticProps$limitId = staticProps.limitId) == null ? void 0 : _staticProps$limitId.toLowerCase()]) || (children == null ? void 0 : children.length);
    var slicedChildren = Array.isArray(children) ? children == null ? void 0 : children.slice(0, limit) : children;
    var adUnitChildren = React.Children.map(slicedChildren, function (child) {
      var _child$props, _child$props$slice;
      var target = staticProps.target;
      var childSliceProps = (child == null ? void 0 : (_child$props = child.props) == null ? void 0 : (_child$props$slice = _child$props.slice) == null ? void 0 : _child$props$slice["static"]) || {};
      var staticChildren = _extends({}, childSliceProps, {
        target: target
      });
      var module = React.cloneElement(child, {
        slotOnload: handleSlotOnLoad,
        "static": _extends({}, staticChildren)
      });
      return {
        isEmpty: false,
        module: module
      };
    });
    setAdUnits(adUnitChildren);
  }, [structure.asPath]);
  return adUnits.length > 0 && React.createElement(Carousel, staticProps, adUnits.map(function (_ref5) {
    var module = _ref5.module,
      isEmpty = _ref5.isEmpty;
    return !isEmpty && module;
  }));
};
CarouselAdUnit.defaultProps = {
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? CarouselAdUnit.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  "static": shape({}),
  structure: shape({})
} : void 0;
CarouselAdUnit.ssr = true;
export default withLayoutProps(CarouselAdUnit);