import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import Head from 'next/head';
import { string, shape } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import { DIALOGFLOW_SCRIPT } from './constants';
import { Box } from './ChatbotLu.styles';
var ChatbotLu = function ChatbotLu(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure;
  var pagetype = structure.name;
  var titleCategory = staticProps.titleCategory,
    agentId = staticProps.agentId,
    title = staticProps.title,
    lang = staticProps.lang,
    toggleAgatha = staticProps.toggleAgatha,
    libUrl = staticProps.libUrl;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _React$useState = React.useState(true),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    clickAvailability = _React$useState2[0],
    setClickAvailability = _React$useState2[1];
  var _React$useState3 = React.useState(true),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    ballonMessageEnabled = _React$useState4[0],
    setBallonMessageEnabled = _React$useState4[1];
  var closeBallonMessage = function closeBallonMessage() {
    setBallonMessageEnabled(false);
  };
  React.useEffect(function () {
    if (toggleAgatha) publish('chatbotLu:view', {
      pagetype: pagetype
    });
  }, [JSON.stringify(structure)]);
  var handleChatbotClick = function handleChatbotClick() {
    if (clickAvailability) {
      setClickAvailability(false);
      publish('chatbotLu:click', {
        pagetype: pagetype
      });
    }
    closeBallonMessage();
  };
  return toggleAgatha && React.createElement(React.Fragment, null, React.createElement(Head, null, React.createElement("script", {
    src: libUrl
  })), React.createElement(Box, null, React.createElement("df-messenger", {
    "df-cx": "true",
    location: "us-central1",
    "project-id": "cx-agatha-prod",
    intent: "start_conversation",
    "wait-open": "true",
    "disable-user-input": "true",
    "agent-id": agentId,
    "language-code": lang,
    className: "chatbotLu",
    "storage-option": "none",
    "data-testid": "chatbot-agatha",
    "url-allowlist": "*",
    onClick: handleChatbotClick
  }, React.createElement("df-messenger-chat-bubble", {
    "chat-title": title,
    "chat-icon": "https://wx.mlcdn.com.br/site/chat/lu-icon-chatbot.png",
    className: "chatbotLuButton",
    "placeholder-text": "Pergunte algo"
  })), ballonMessageEnabled ? React.createElement("div", {
    "data-testid": "chatbot-ballon-message",
    className: "ballonMessage"
  }, "Oi! Agora t\xF4 usando intelig\xEAncia artificial pra te ajudar a fazer as\n              melhores escolhas de ".concat(titleCategory, ". \xC9 s\xF3 me perguntar!"), React.createElement("button", {
    "data-testid": "chatbot-close-ballon",
    type: "button",
    onClick: closeBallonMessage
  }, "x")) : ''), React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: DIALOGFLOW_SCRIPT
    }
  }));
};
process.env.NODE_ENV !== "production" ? ChatbotLu.propTypes = {
  "static": shape({
    agentId: string,
    lang: string,
    libUrl: string,
    title: string,
    titleCategory: string
  }),
  structure: shape({})
} : void 0;
ChatbotLu.defaultProps = {
  "static": {
    agentId: '7d9db816-6044-43ea-958d-27739901cf0a',
    lang: 'pt-br',
    libUrl: 'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js',
    title: 'Fale com a Lu'
  },
  structure: {}
};
ChatbotLu.ssr = true;
export default ChatbotLu;