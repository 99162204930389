import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import BreadcrumbModule from '@magalu/stereo-ui/molecules/Breadcrumb';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { getFullTitle, routePush } from '@magalu/mixer-utils';
import { parseBreadcrumb } from './Breadcrumb.utils';
function Breadcrumb(_ref) {
  var _structure$route;
  var data = _ref.data,
    structure = _ref.structure,
    _ref$static = _ref["static"],
    logo = _ref$static.logo,
    basePath = _ref$static.basePath,
    headItems = _ref$static.headItems;
  var _ref2 = (structure == null ? void 0 : structure.config) || {},
    domain = _ref2.domain;
  var product = data == null ? void 0 : data.product;
  var fullTitle = getFullTitle(product);
  var customProduct = {
    sku: structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.productId,
    subTitle: product == null ? void 0 : product.reference,
    title: fullTitle,
    url: product == null ? void 0 : product.url
  };
  var breadcrumb = parseBreadcrumb({
    basePath: basePath,
    data: data,
    items: headItems,
    product: customProduct,
    structure: structure
  });
  var handleLogoClick = function handleLogoClick(e) {
    e.preventDefault();
    var path = (logo == null ? void 0 : logo.url) || '/';
    routePush({
      path: path,
      spa: true
    });
  };
  var handleClickItem = function handleClickItem(e, item) {
    var url = item.url,
      categorySlug = item.categorySlug;
    if (categorySlug) {
      e.preventDefault();
      routePush({
        path: url,
        spa: true
      });
    }
  };
  return React.createElement(Box, {
    overflowY: "hidden",
    "data-testid": "breadcrumb-container",
    width: "100%"
  }, React.createElement(BreadcrumbModule, {
    items: breadcrumb,
    domain: domain,
    onClickItem: handleClickItem,
    onLogoClick: handleLogoClick,
    logo: logo
  }));
}
process.env.NODE_ENV !== "production" ? Breadcrumb.propTypes = {
  data: shape({
    product: shape({}),
    seo: shape({
      category: arrayOf(shape({}))
    })
  }),
  "static": shape({
    basePath: string.isRequired,
    headItems: arrayOf(shape({})).isRequired,
    logo: shape({
      icon: string.isRequired,
      styles: shape({}),
      title: string.isRequired,
      url: string.isRequired
    })
  }),
  structure: shape({}).isRequired
} : void 0;
Breadcrumb.defaultProps = {
  data: {},
  "static": {}
};
Breadcrumb.ssr = true;
Breadcrumb.dataSource = {
  category: {
    query: 'CategoryQuery'
  }
};
Breadcrumb.displayName = 'Breadcrumb';
export default withLayoutProps(Breadcrumb);