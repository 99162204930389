import React from 'react';
import { shape, string } from 'prop-types';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Title, Content, InfoText, Button } from './ConfirmationReporting.styles';
var ConfirmationReporting = function ConfirmationReporting(_ref) {
  var staticProps = _ref["static"];
  var infoText = staticProps.infoText,
    pathToRedirect = staticProps.pathToRedirect,
    title = staticProps.title;
  var handleRedirectOnClick = function handleRedirectOnClick() {
    return routePush({
      path: pathToRedirect,
      spa: true
    });
  };
  return React.createElement(Content, null, React.createElement(Title, null, title), React.createElement(InfoText, null, infoText), React.createElement(Button, {
    "data-testid": "confirmationReporting-redirect-button",
    onClick: function onClick() {
      return handleRedirectOnClick();
    }
  }, "Voltar ao in\xEDcio"));
};
process.env.NODE_ENV !== "production" ? ConfirmationReporting.propTypes = {
  "static": shape({
    infoText: string,
    pathToRedirect: string,
    title: string
  }).isRequired
} : void 0;
ConfirmationReporting.ssr = false;
ConfirmationReporting.displayName = 'ConfirmationReporting';
export default withLayoutProps(ConfirmationReporting);