import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import Link from '@magalu/stereo-ui/atoms/Link';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './BrandList.styles';
var BrandList = function BrandList(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var brands = data == null ? void 0 : data.brandList.brands;
  var basePath = structure.basePath;
  var onBrandClick = function onBrandClick(e, slug) {
    e.preventDefault();
    routePush({
      path: "".concat(basePath, "/marcas/").concat(slug),
      spa: true
    });
  };
  return React.createElement(Container, {
    "data-testid": "ctn-brands",
    columns: staticProps.columns
  }, brands.map(function (item) {
    return React.createElement(Link, {
      onClick: function onClick(e) {
        onBrandClick(e, item.slug);
      },
      key: item.slug,
      href: "".concat(basePath, "/marcas/").concat(item.slug, "/"),
      my: 2,
      as: "a",
      color: "text.primary",
      display: "block",
      style: {
        wordBreak: 'break-all'
      }
    }, item.brand);
  }));
};
BrandList.ssr = true;
process.env.NODE_ENV !== "production" ? BrandList.propTypes = {
  data: shape({
    brandList: shape({
      brands: arrayOf(shape({
        brand: string,
        slug: string
      }))
    })
  }),
  "static": shape({
    columns: number
  }),
  structure: shape({
    basePath: string
  })
} : void 0;
BrandList.defaultProps = {
  data: {
    brandList: {
      brands: []
    }
  },
  "static": {
    columns: 4
  },
  structure: {
    basePath: ''
  }
};
BrandList.dataSource = {
  brandList: {
    query: 'AllBrandsQuery'
  }
};
export default withLayoutProps(BrandList);