import React from 'react';
import { shape } from 'prop-types';
import Text from '@magalu/stereo-ui/atoms/Text';
import Review from '@magalu/stereo-ui/molecules/Review';
import GenericButton from '../GenericButton/GenericButton';
import { Content, ReviewContent } from './EmptyReviewButton.styles';
var EmptyReviewButton = function EmptyReviewButton(_ref) {
  var _data$productRating, _data$productRating$u, _data$productRating$u2;
  var data = _ref.data,
    staticProps = _ref["static"];
  var title = staticProps.title,
    subtitle = staticProps.subtitle,
    genericButtonProps = staticProps.genericButtonProps;
  var count = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$u = _data$productRating.userReviews) == null ? void 0 : (_data$productRating$u2 = _data$productRating$u.items) == null ? void 0 : _data$productRating$u2.length) || 0;
  return count === 0 && React.createElement(Content, {
    "data-testid": "empty-review-button"
  }, React.createElement(Text, {
    as: "p",
    color: "inherit",
    fontSize: 4,
    fontWeight: "medium"
  }, title), Boolean(subtitle) && React.createElement(Text, {
    as: "p",
    fontSize: 3,
    fontWeight: "regular",
    paddingTop: "12px"
  }, subtitle), React.createElement(ReviewContent, null, React.createElement(Review, {
    width: "40px",
    heigth: "40px"
  })), React.createElement(GenericButton, genericButtonProps));
};
EmptyReviewButton.defaultProps = {
  data: {},
  "static": {
    title: 'Seja o primeiro a avaliar esse produto'
  }
};
process.env.NODE_ENV !== "production" ? EmptyReviewButton.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
EmptyReviewButton.ssr = true;
export default EmptyReviewButton;