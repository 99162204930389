import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import Link from '@magalu/stereo-ui/atoms/Link';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import { getTextColor, themeGet } from '@magalu/stereo-ui-styles';
export var LuDetailContent = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: ", ";\n  img {\n    width: 100% !important;\n    height: auto !important;\n  }\n  p {\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    line-height: 20px;\n  }\n  a {\n    color: ", ";\n    text-decoration: none;\n  }\n\n  > h2 {\n    line-height: 22px;\n  }\n"])), function (props) {
  return props.openText ? 'block' : 'none';
}, function (props) {
  return getTextColor('primary')(props);
});
export var ShowMore = styled(Link)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  margin-top: ", "px;\n  text-align: center;\n\n  &:hover {\n    cursor: pointer;\n  }\n"])), function (props) {
  return getTextColor('primary')(props);
}, themeGet('fontSizes.2'), themeGet('space.2'));
export var Title = styled(Text)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n  margin-bottom: ", "px;\n  margin-top: ", "px;\n"])), function (props) {
  return getTextColor('black')(props);
}, themeGet('fontSizes.3'), themeGet('fontWeights.bold'), themeGet('space.2'), themeGet('space.3'));