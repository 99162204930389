import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { node, oneOfType, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import RichDescription from '../RichDescription';
import { LuDetailContent, ShowMore, Title } from './DocumentSupportText.styles';
var DocumentSupportText = function DocumentSupportText(_ref) {
  var staticProps = _ref["static"];
  var _ref2 = staticProps || {},
    content = _ref2.content,
    preview = _ref2.preview,
    title = _ref2.title;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openText = _useState2[0],
    setOpenText = _useState2[1];
  return preview && React.createElement(Box, {
    margin: 2,
    borderTopWidth: 1,
    borderTopColor: "whisper",
    borderTopStyle: "solid"
  }, React.createElement(Title, {
    as: "h2"
  }, title), React.createElement(RichDescription, {
    "static": {
      fontSize: 2,
      lineHeight: '1.3em',
      richContent: preview
    }
  }), React.createElement(Box, {
    marginTop: 3
  }, React.createElement(LuDetailContent, {
    "data-testid": "support-text-content",
    openText: openText,
    dangerouslySetInnerHTML: {
      __html: content
    }
  })), React.createElement(Box, {
    textAlign: "center",
    marginBottom: 4
  }, content && React.createElement(ShowMore, {
    as: "a",
    role: "button",
    onClick: function onClick() {
      return setOpenText(!openText);
    },
    "data-testid": "support-text-link"
  }, openText ? 'Ver menos' : 'Ver mais')));
};
process.env.NODE_ENV !== "production" ? DocumentSupportText.propTypes = {
  "static": shape({
    content: oneOfType([node, string]),
    preview: oneOfType([node, string]),
    title: string
  })
} : void 0;
DocumentSupportText.defaultProps = {
  "static": {}
};
DocumentSupportText.ssr = true;
export default DocumentSupportText;