import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { ExchangeAndRefund } from './ExchangeAndRefund';
import { DeliveryAssurance } from './DeliveryAssurance';
import { RecommendedProduct } from './RecommendedProduct';
var variations = {
  deliveryAssurance: {
    component: DeliveryAssurance,
    eventPrefix: 'deliveryassurance',
    title: 'Entrega e compra garantida'
  },
  exchangeAndRefund: {
    component: ExchangeAndRefund,
    eventPrefix: 'exchangeandrefund',
    title: 'Política de trocas e devoluções'
  },
  recommendedProduct: {
    component: RecommendedProduct,
    eventPrefix: 'recommendedproduct',
    subtitle: React.createElement(RecommendedProduct.Title, {
      color: "text.lightest",
      lineHeight: 1.5
    }),
    title: 'Magalu Indica'
  }
};
var getVariations = function getVariations() {
  var variationList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return variationList.map(function (variation) {
    if (!variations[variation]) {
      return null;
    }
    return _extends({
      id: variation
    }, variations[variation]);
  });
};
export { getVariations, variations };