import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { getBackgroundColor } from '@magalu/stereo-ui-styles';
import BuyBoxList from '@magalu/stereo-ui/templates/BuyBox';
import Box from '@magalu/stereo-ui/atoms/Box';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Text from '@magalu/stereo-ui/atoms/Text';
import StereoShippingButton from '@magalu/stereo-ui/molecules/ShippingButton';
export var BuyBoxListStyled = styled(BuyBoxList).attrs(function (props) {
  return _extends({}, props, {
    mb: 3
  });
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  min-height: 300px;\n"])), function (props) {
  return getBackgroundColor('white')(props);
});
export var Container = styled(Box).attrs(function (props) {
  return _extends({}, props, {
    backgroundColor: 'background.base'
  });
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var ImageItem = styled(Image)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  cursor: ", ";\n"])), function (props) {
  return props.width;
}, function (props) {
  return props.height;
}, function (_ref) {
  var addProductLink = _ref.addProductLink;
  return addProductLink ? 'pointer' : 'auto';
});
export var Line = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  background: ", ";\n  margin-bottom: 2%;\n"])), function (props) {
  return getBackgroundColor('white')(props);
});
export var ShippingButton = styled(StereoShippingButton)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  max-width: 300px;\n"])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    color: 'text.base',
    fontSize: 1,
    lineHeight: 'display',
    maxWidth: '220px',
    mb: 3,
    ml: props.href ? 0 : 2
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));