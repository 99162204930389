import React from 'react';
import { bool } from 'prop-types';
import { Flex, Grid, Text } from '@magalu/stereo-ui';
import { Icon } from '@magalu/stereo-ui-icons';
import { RecommendedProductTitle } from './RecommendedProductTitle';
import { IconTextContainer, ItemTitle } from './RecommendedProduct.styles';
var RecommendedProduct = function RecommendedProduct(_ref) {
  var showDialog = _ref.showDialog;
  return React.createElement(Flex, {
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 4
  }, !showDialog && React.createElement(RecommendedProductTitle, {
    color: "primary.base",
    fontWeight: "bold",
    fontSize: 1
  }), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Pre\xE7o Baixo garantido."), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Quando\xA0", React.createElement(Text, {
    as: "span",
    display: "inline",
    fontWeight: ['regular', 'bold']
  }, "Magalu Indica"), ",\xA0voc\xEA tem sempre as melhores ofertas para o produto que est\xE1 procurando."))), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Entrega mais r\xE1pida e segura."), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Seu produto j\xE1 est\xE1 no nosso centro de distribui\xE7\xE3o. Por isso, ele chega mais r\xE1pido pra voc\xEA."))), React.createElement(IconTextContainer, null, React.createElement(Icon, {
    name: "Check",
    width: [20, 32],
    height: [20, 32]
  }), React.createElement(Grid, {
    gap: [1, 1, 2]
  }, React.createElement(ItemTitle, null, "Parceiros de confian\xE7a."), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.2
  }, "Uma sele\xE7\xE3o de produtos dos vendedores mais bem avaliados do Magalu"))));
};
process.env.NODE_ENV !== "production" ? RecommendedProduct.propTypes = {
  showDialog: bool
} : void 0;
RecommendedProduct.defaultProps = {
  showDialog: false
};
RecommendedProduct.Title = RecommendedProductTitle;
export default RecommendedProduct;