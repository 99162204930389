import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import { Flex, Text as StereoText } from '@magalu/stereo-ui';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var Container = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-direction: column;\n"])));
export var IconTextContainer = styled(Flex).attrs(function () {
  return {
    gap: '2',
    mr: 'auto'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: center;\n  justify-content: center;\n\n  ", " {\n    padding: 0 ", "px;\n  }\n"])), getMediaQuery('medium'), themeGet('space.3'));
export var Text = styled(StereoText).attrs(function (props) {
  return _extends({
    fontSize: 1,
    lineHeight: 1.2
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: inline;\n"])));