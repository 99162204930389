import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { Flex, Text } from '@magalu/stereo-ui';
import { getMediaQuery, getPaletteColor } from '@magalu/stereo-ui-styles';
var gray = getPaletteColor('text.scratched');
var blue = getPaletteColor('background.magablu');
export var IconTextContainer = styled(Flex).attrs(function () {
  return {
    gap: 3
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    color: ", ";\n    fill: ", ";\n  }\n\n  ", " {\n    align-items: flex-start;\n\n    svg {\n      color: ", ";\n      fill: ", ";\n    }\n  }\n"])), gray, gray, getMediaQuery('small'), blue, blue);
export var ItemTitle = styled(Text).attrs(function () {
  return {
    color: 'text.base',
    fontSize: [1, 1, 2],
    fontWeight: 'bold',
    lineHeight: 1
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", " {\n    color: ", ";\n  }\n"])), getMediaQuery('small'), blue);