import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Text from '@magalu/stereo-ui/atoms/Text';
export var Description = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 0,
    lineHeights: 'input'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Label = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 1,
    fontWeight: 'medium'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-decoration: underline;\n"])));