import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import { Box, Dialog } from '@magalu/stereo-ui';
export var Container = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  svg {\n    flex-shrink: 0;\n  }\n"])));
export var DialogHeader = styled(Box).attrs(function () {
  return {
    bg: 'background.magablu',
    px: 24,
    py: 4
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: 164px;\n  display: flex;\n  flex-direction: column;\n"])));
export var DialogStyled = styled(Dialog)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 360px;\n  padding: 0px;\n\n  svg {\n    flex-shrink: 0;\n  }\n"])));