import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import { themeGet, getTextColor } from '@magalu/stereo-ui-styles';
export var Link = styled.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  font-weight: ", ";\n  line-height: ", ";\n  color: ", ";\n  cursor: pointer;\n"])), function (props) {
  return themeGet('fontSizes.1')(props);
}, function (props) {
  return themeGet('fontWeights.regular')(props);
}, function (props) {
  return themeGet('lineHeights.big')(props);
}, function (props) {
  return getTextColor('default')(props);
});