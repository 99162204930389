import { usePublisher } from '@magalu/mixer-publisher';
import { isVoltageAttribute } from '@magalu/mixer-utils';
import { PRODUCT_ATTRIBUTE_LIST_SHOW_WARNING_VOLTAGE_TEXT } from '../../../commons';
import { chooseVoltage } from './constants';
var useBuyButtonsHelper = function useBuyButtonsHelper() {
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePublishWaringText = function handlePublishWaringText(type) {
    if (isVoltageAttribute(type)) {
      publish(PRODUCT_ATTRIBUTE_LIST_SHOW_WARNING_VOLTAGE_TEXT, {
        icon: 'AlertOutline',
        message: chooseVoltage,
        showComponent: true
      });
    }
  };
  return {
    handlePublishWaringText: handlePublishWaringText
  };
};
export default useBuyButtonsHelper;