import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import React, { useEffect, useState } from 'react';
import { bool, arrayOf, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Text, Sidebar } from '@magalu/stereo-ui';
import { usePublisher } from '@magalu/mixer-publisher';
import { getVariations } from './Variations';
import { Container, DialogStyled, DialogHeader } from './BenefitsModal.styles';
var BenefitsModal = function BenefitsModal(_ref) {
  var product = _ref.data.product,
    _ref$static = _ref["static"],
    showDialog = _ref$static.showDialog,
    variations = _ref$static.variations,
    _ref$static$variation = _ref$static.variationProps,
    variationProps = _ref$static$variation === void 0 ? {} : _ref$static$variation;
  var variationList = getVariations(_toConsumableArray(new Set(variations))).filter(function (variation) {
    var _product$offerTags;
    return !((variation == null ? void 0 : variation.id) === 'recommendedProduct' && !(product == null ? void 0 : (_product$offerTags = product.offerTags) == null ? void 0 : _product$offerTags.includes('magalu_indica')));
  });
  return variationList.map(function (variation) {
    if (!variation) {
      return null;
    }
    return React.createElement(Wrapper, _extends({
      key: variation.title,
      variation: variation,
      seller: product == null ? void 0 : product.seller,
      showDialog: showDialog
    }, variationProps[variation.id]));
  });
};
var Wrapper = function Wrapper(_ref2) {
  var modalOpen = _ref2.open,
    showDialog = _ref2.showDialog,
    variation = _ref2.variation,
    seller = _ref2.seller;
  var _useState = useState(modalOpen),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var title = variation.title,
    subtitle = variation.subtitle,
    eventPrefix = variation.eventPrefix,
    ModalContent = variation.component;
  var isDeliveredByMagalu = seller.deliveryId === 'magazineluiza';
  var isSoldByMagalu = seller.category === '1p';
  var toggleModal = function toggleModal() {
    return setOpen(!open);
  };
  var closeModal = function closeModal() {
    return setOpen(false);
  };
  useEffect(function () {
    var toggleEvent = "".concat(eventPrefix, "modal:toggle");
    subscribe(toggleEvent, toggleModal);
    return function () {
      unsubscribe(toggleEvent, toggleModal);
    };
  });
  return showDialog ? React.createElement(DialogStyled, {
    position: "center",
    type: "responsive",
    showClose: false,
    visible: open,
    onClickOutside: closeModal
  }, React.createElement(DialogHeader, null, React.createElement(Icon, {
    name: "Close",
    color: "text.white",
    cursor: "pointer",
    "aria-label": "Close",
    "data-testid": "CloseIcon",
    ml: "auto",
    display: "block",
    onClick: closeModal
  }), React.createElement(Text, {
    mt: "auto",
    color: "text.white",
    fontSize: 5,
    fontWeight: "bold",
    lineHeight: 1.2
  }, title), subtitle && React.createElement(Container, {
    mt: 2
  }, typeof subtitle === 'string' ? React.createElement(Text, {
    fontSize: 2,
    lineHeight: 1.5,
    color: "text.lightest"
  }, subtitle) : subtitle)), React.createElement(Container, {
    p: 4
  }, React.createElement(ModalContent, {
    isSoldByMagalu: isSoldByMagalu,
    isDeliveredByMagalu: isDeliveredByMagalu,
    showDialog: showDialog
  }))) : React.createElement(Sidebar, {
    direction: "right",
    visible: open,
    onClickOutside: closeModal
  }, React.createElement(Sidebar.Header, {
    icon: "back",
    textAlign: "center",
    label: title,
    onClick: closeModal
  }, title), React.createElement(Container, {
    px: 3,
    pt: 2
  }, React.createElement(ModalContent, {
    isSoldByMagalu: isSoldByMagalu,
    isDeliveredByMagalu: isDeliveredByMagalu,
    showDialog: showDialog
  })));
};
process.env.NODE_ENV !== "production" ? Wrapper.propTypes = {
  open: bool,
  seller: shape({}),
  showDialog: bool,
  variation: shape({})
} : void 0;
Wrapper.defaultProps = {
  open: false,
  seller: {},
  showDialog: false,
  variation: {}
};
process.env.NODE_ENV !== "production" ? BenefitsModal.propTypes = {
  data: shape({
    product: shape({
      seller: shape({})
    })
  }),
  "static": shape({
    showDialog: bool,
    variationProps: shape({}),
    variations: arrayOf(string)
  })
} : void 0;
BenefitsModal.defaultProps = {
  data: {
    product: {
      seller: {}
    }
  },
  "static": {
    showDialog: false,
    variationProps: shape({}),
    variations: []
  }
};
BenefitsModal.ssr = true;
export default BenefitsModal;