import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import { Image, Link } from '@magalu/stereo-ui/atoms';
import { Carousel } from '@magalu/stereo-ui/molecules';
import withLayoutProps from '../../../hocs/withLayoutProps';
var CarouselBanners = function CarouselBanners(_ref) {
  var staticProps = _ref["static"];
  var banners = staticProps == null ? void 0 : staticProps.items;
  var parseUrl = function parseUrl(url) {
    var parsedUrl = url;
    if (typeof parsedUrl !== 'string') {
      return null;
    }
    if (staticProps.parse) {
      staticProps.parse.forEach(function (parse) {
        parsedUrl = parsedUrl.replace(parse.key, parse.value);
      });
    }
    return parsedUrl;
  };
  return (banners == null ? void 0 : banners.length) > 0 && React.createElement(Carousel, _extends({}, staticProps, {
    "data-testid": "pmd-carousel-banners",
    margin: "24px 0"
  }), banners.map(function (banner) {
    return React.createElement(Link, {
      key: banner.id,
      href: parseUrl(banner.url),
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "data-testid": "pmd-carousel-link-".concat(banner.id)
    }, React.createElement(Image, {
      borderRadius: "10px",
      src: banner.image,
      alt: banner.altText
    }));
  }));
};
CarouselBanners.defaultProps = {
  "static": {}
};
process.env.NODE_ENV !== "production" ? CarouselBanners.propTypes = {
  "static": shape({})
} : void 0;
CarouselBanners.ssr = true;
export default withLayoutProps(CarouselBanners);