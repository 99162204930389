import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { slug } from '@magalu/mixer-utils';

var getCategoryTree = function getCategoryTree(structure, data) {
  var _ref = structure || {},
    route = _ref.route;
  var _ref2 = (data == null ? void 0 : data.category) || [],
    _ref3 = _slicedToArray(_ref2, 1),
    category = _ref3[0];
  var _ref4 = category || {},
    subcategories = _ref4.subcategories;
  var _ref5 = subcategories || [],
    _ref6 = _slicedToArray(_ref5, 1),
    firstSubcategory = _ref6[0];
  var subcategory = (subcategories == null ? void 0 : subcategories.find(function (item) {
    return (item == null ? void 0 : item.id) === (route == null ? void 0 : route.subCategoryId);
  })) || firstSubcategory;
  return {
    category: category,
    subcategory: subcategory
  };
};

var shouldGetSubcategories = function shouldGetSubcategories(subcategory, subCategoryId) {
  var _subcategory$subcateg;
  return Array.isArray(subcategory == null ? void 0 : subcategory.subcategories) && Boolean(subcategory == null ? void 0 : (_subcategory$subcateg = subcategory.subcategories) == null ? void 0 : _subcategory$subcateg.length) && (subcategory == null ? void 0 : subcategory.id) !== subCategoryId;
};

var findSubcategoryById = function findSubcategoryById() {
  var subcategory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var subCategoryId = arguments.length > 1 ? arguments[1] : undefined;
  var subcategories = subcategory.subcategories;
  return (subcategories == null ? void 0 : subcategories.find(function (item) {
    return (item == null ? void 0 : item.id) === subCategoryId;
  })) || subcategories[0];
};

var getBreadcrumbItems = function getBreadcrumbItems(category, subcategory, paths) {
  var _category$id, _subcategory$id;
  var position = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;
  var basePath = paths.basePath,
    categorySlug = paths.categorySlug,
    subCategoryId = paths.subCategoryId;
  var subCategorySlug = (subcategory == null ? void 0 : subcategory.label) && slug(subcategory == null ? void 0 : subcategory.label);
  var item = {
    categoryId: category == null ? void 0 : category.id,
    name: (subcategory == null ? void 0 : subcategory.label) || '',
    position: position,
    subCategorySlug: subCategorySlug,
    url: "".concat(basePath, "/").concat(subCategorySlug, "/").concat(categorySlug, "/s/").concat(category == null ? void 0 : (_category$id = category.id) == null ? void 0 : _category$id.toLowerCase(), "/").concat(subcategory == null ? void 0 : (_subcategory$id = subcategory.id) == null ? void 0 : _subcategory$id.toLowerCase(), "/")
  };
  return shouldGetSubcategories(subcategory, subCategoryId) ? [item, getBreadcrumbItems(category, findSubcategoryById(subcategory, subCategoryId), paths, position + 1)].flat(Infinity) : [item];
};

var shouldInvokeBreadcrumbFN = function shouldInvokeBreadcrumbFN(_ref7) {
  var data = _ref7.data,
    key = _ref7.key,
    validations = _ref7.validations;
  if (data[key]) {
    var _validations$key, _validations$key2, _validations$key2$req;
    if (validations && Boolean(Object.keys(validations).length) && Array.isArray((_validations$key = validations[key]) == null ? void 0 : _validations$key.requiredkeys) && Boolean((_validations$key2 = validations[key]) == null ? void 0 : (_validations$key2$req = _validations$key2.requiredkeys) == null ? void 0 : _validations$key2$req.length)) {
      var _validations$key3;
      return (_validations$key3 = validations[key]) == null ? void 0 : _validations$key3.requiredkeys.every(function (requiredKey) {
        return data[key][requiredKey];
      });
    }
    return Boolean(Object.keys(data[key]).length);
  }
  return false;
};

var buildBreadcrumbByPageData = function buildBreadcrumbByPageData() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref8.structure,
    data = _ref8.data,
    basePath = _ref8.basePath,
    validations = _ref8.validations;
  var _category = data.category,
    _subcategory = data.subcategory,
    _product = data.product;
  var _ref9 = (structure == null ? void 0 : structure.route) || {},
    subCategoryId = _ref9.subCategoryId;
  var categorySlug = (_category == null ? void 0 : _category.description) && slug(_category.description);

  var inventory = {
    category: function category() {
      var _category$id2;
      return {
        categoryId: _category == null ? void 0 : _category.id,
        categorySlug: categorySlug,
        name: _category == null ? void 0 : _category.label,
        position: 2,
        url: "".concat(basePath, "/").concat(categorySlug, "/l/").concat(_category == null ? void 0 : (_category$id2 = _category.id) == null ? void 0 : _category$id2.toLowerCase(), "/")
      };
    },
    subcategory: function subcategory() {
      if (subCategoryId) return getBreadcrumbItems(_category, _subcategory, {
        basePath: basePath,
        categorySlug: categorySlug,
        subCategoryId: subCategoryId
      });
      return {};
    },
    product: function product(breadcrumbs) {
      var productURL = (_product == null ? void 0 : _product.url) ? "".concat(basePath, "/").concat(_product.url) : '';
      var productPath = (_product == null ? void 0 : _product.path) ? "".concat(basePath, "/").concat(_product.path) : '';
      var productSlug = slug(_product == null ? void 0 : _product.title);
      return {
        categoryId: _category == null ? void 0 : _category.id,
        name: (_product == null ? void 0 : _product.title) || '',
        position: (breadcrumbs == null ? void 0 : breadcrumbs.length) + 2,
        productSlug: productSlug,
        url: (_product == null ? void 0 : _product.path) ? productPath : productURL
      };
    }
  };
  return Object.keys(inventory).reduce(function (breadcrumbs, key) {
    if (shouldInvokeBreadcrumbFN({
      data: data,
      key: key,
      validations: validations
    })) {
      var breadcrumb = inventory[key](_toConsumableArray(breadcrumbs));
      breadcrumb = Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb];
      breadcrumbs.push.apply(breadcrumbs, _toConsumableArray(breadcrumb));
    }
    return breadcrumbs;
  }, []).filter(Boolean);
};

export var parseBreadcrumb = function parseBreadcrumb() {
  var _breadcrumb;
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref10.structure,
    _ref10$basePath = _ref10.basePath,
    basePath = _ref10$basePath === void 0 ? '' : _ref10$basePath,
    data = _ref10.data,
    product = _ref10.product,
    items = _ref10.items;
  var breadcrumb = [];
  var _ref11 = data || {},
    _search = _ref11.search;
  var _getCategoryTree = getCategoryTree(structure, data),
    category = _getCategoryTree.category,
    subcategory = _getCategoryTree.subcategory;
  var _ref12 = structure || {},
    asPath = _ref12.asPath,
    pageId = _ref12.id,
    route = _ref12.route;

  var inventory = {
    seller: function seller() {
      var _first$seller;
      var _ref13 = (_search == null ? void 0 : _search.products) || [],
        _ref14 = _slicedToArray(_ref13, 1),
        first = _ref14[0];
      return [{
        name: first == null ? void 0 : (_first$seller = first.seller) == null ? void 0 : _first$seller.description,
        position: 2,
        url: asPath
      }];
    },
    selection: function selection() {
      var _search$selection;
      return [{
        name: _search == null ? void 0 : (_search$selection = _search.selection) == null ? void 0 : _search$selection.name,
        position: 2,
        url: asPath
      }];
    },
    brand: function brand() {
      var brandsURL = asPath.replace("".concat(route.brandId, "/"), '');
      return [{
        name: 'todas as marcas',
        position: 2,
        url: brandsURL
      }, {
        name: route == null ? void 0 : route.brandId,
        position: 3,
        url: asPath
      }];
    },
    search: function search() {
      var _search$filters;
      var breadcrumbURL = "".concat(asPath, "?filters=category-");
      var term = route == null ? void 0 : route.term;
      return _search == null ? void 0 : (_search$filters = _search.filters) == null ? void 0 : _search$filters.reduce(function (acc, item) {
        var type = item.type,
          values = item.values;
        if (type === 'category') {
          values == null ? void 0 : values.forEach(function () {
            var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
              id = _ref15.id,
              label = _ref15.label,
              selected = _ref15.selected;
            if (selected) {
              breadcrumbURL = "".concat(breadcrumbURL, "--").concat(id);
              acc.push({
                name: label,
                url: breadcrumbURL
              });
            }
          });
        }
        return acc;
      }, [{
        name: "resultados para ".concat(term),
        position: 2,
        url: asPath
      }]);
    },
    "default": function _default() {
      return buildBreadcrumbByPageData({
        structure: structure,
        data: {
          category: category,
          product: product,
          subcategory: subcategory
        },
        basePath: basePath,
        validations: {
          product: {
            requiredkeys: ['sku']
          }
        }
      });
    }
  };
  breadcrumb = inventory[pageId] ? inventory[pageId]() : inventory["default"]();
  if (items == null ? void 0 : items.length) (_breadcrumb = breadcrumb).push.apply(_breadcrumb, _toConsumableArray(items));
  return breadcrumb;
};